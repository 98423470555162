<template>
  <nav v-if="nav" id="adminDesktopNav" class="free-trial" :class="navEnlarge && 'navEnlarge'">
    <img v-if="navEnlarge" src="@/assets/logo-wide-light.png" alt="logo" id="logo" />
    <img v-else src="@/assets/logo-clear.png" alt="logo" id="logo" />
    <router-link to="/messages"><i class="fa fa-paper-plane"></i>
      <span>Messages</span></router-link>
    <router-link to="/calls"><i class="fa fa-phone"></i>
      <span>Calls</span></router-link>
    <router-link to="/channels"><i class="fa-regular fa-message"></i>
      <span>Channels</span></router-link>
    <router-link to="/info"><i class="fa fa-database"></i>
      <span>Knowledge</span></router-link>
    <router-link to="/settings"><i class="fa fa-gear"></i>
      <span>Setup</span></router-link>
    <a href="mailto:contact@bookedsolid.co.uk"><i class="fa fa-life-ring"></i>
      <span>Get Help</span></a>

    <div id="selectISV" v-if="showClients">
      <a v-for="client of isv.subaccounts" @click="setClient(client)"><span>{{ client.name }}</span></a>
      <router-link to="/isv"><i class="fa fa-plus"></i><span>Create new</span></router-link>
    </div>
    <div id="isvToggle" v-if="isv" @click="showClients = !showClients"><span>{{ client?.name || 'Loading' }}</span><i
        class="fa-solid fa-repeat"></i></div>
    <i class="fa" :class="navEnlarge ? 'fa-chevron-left' : 'fa-chevron-right'" id="openNav" @click="toggleNavEnlarge"
      v-if="!isv"></i>
  </nav>
  <nav v-if="mobileNav && this.$route.name != 'login'" id="adminMobileNav" :class="isv && 'isv'">
    <div id="flex-nav-items">
      <router-link to="/messages"><i class="fa fa-paper-plane"></i></router-link>
      <router-link to="/calls"><i class="fa fa-phone"></i></router-link>
      <router-link to="/channels"><i class="fa fa-message"></i></router-link>
      <router-link to="/info"><i class="fa fa-database"></i></router-link>
      <router-link to="/settings"><i class="fa fa-gear"></i></router-link>
    </div>
    <div id="selectISV" v-if="showClients">
      <a v-for="client of isv.subaccounts" @click="setClient(client)"><span>{{ client.name }}</span></a>
      <router-link to="/isv"><i class="fa fa-plus"></i><span>Create new</span></router-link>
    </div>
    <div id="isvToggle" v-if="isv" @click="showClients = !showClients"><span>{{ client?.name || 'Loading' }}</span><i
        class="fa-solid fa-repeat"></i></div>
  </nav>

  <div v-if="!hasPaid && !mobileNav && this.$route.name != 'login'" id="free-trial-banner"
    :class="navEnlarge && 'navEnlarge'">Free Trial: {{ this.freeTrial }} {{ this.freeTrial == 1 ? 'day' : 'days' }}
    remaining</div>
  <router-view class="router-view" :class="navEnlarge && 'navEnlarge', (!hasPaid && !mobileNav) && 'free-trial', isv && 'isv'"
    :website="website" :showMobileNav="showMobileNav" :hideMobileNav="hideMobileNav" />
</template>

<script>
import { getClientSettings, getISV } from '@/app/admin-routes';

export default {
  name: 'App',
  data() {
    return {
      isv: null,
      client: null,
      authed: false,
      admin: true,
      nav: true,
      email: localStorage.email,
      mobileNav: false,
      showNav: false,
      navEnlarge: localStorage.navEnlarge == 'true' ? true : false,
      route: this.$route.name,
      settings: {
        integration: 'cliniko',
        name: '',
      },
      showInfo: false,
      hasPaid: true,
      freeTrial: 0,
      showClients: false,
    };
  },
  methods: {
    async getSettings() {
      const settings = await getClientSettings();
      if (!settings) {
        localStorage.removeItem('settings');
        return;
      }
      document.documentElement.style.setProperty('--colourP', '#0074d9');
      document.documentElement.style.setProperty('--colourS', '#7fdbff');
      document.documentElement.style.setProperty('--colourT', '#39cccc');
      document.documentElement.style.setProperty('--text', '#2c3e50');
      localStorage.setItem('settings', JSON.stringify(settings));
      this.settings = settings;
      // Apply the settings
      if (!settings.hasPaid && !localStorage.isv_id) {
        if (localStorage.registrationDate) {
          const registrationDate = new Date(localStorage.registrationDate);
          console.log(registrationDate);
          let trialPeriod = 30;
          if (new Date(localStorage.registrationDate).getTime() > new Date('2025-01-18').getTime()) {
            trialPeriod = 14;
          }
          const daysRemaining = 30 - Math.floor((new Date() - registrationDate) / (1000 * 60 * 60 * 24));
          if (daysRemaining) {
            this.hasPaid = false;
            this.freeTrial = daysRemaining;
            if (this.freeTrial < 0) {
              this.freeTrial = 0;
            }
          }
        }
      }
    },
    setSize() {
      if (window.innerWidth < 600) {
        this.nav = false;
        this.mobileNav = true;
      } else {
        this.nav = true;
        this.mobileNav = false;
      }
    },
    toggleNavEnlarge() {
      if (this.navEnlarge) {
        this.navEnlarge = false;
        localStorage.navEnlarge = 'false';
      } else {
        this.navEnlarge = true;
        localStorage.navEnlarge = 'true';
      }
      this.showInfo = false;
    },
    showMobileNav() {
      this.mobileNav = true;
    },
    hideMobileNav() {
      this.mobileNav = false;
    },
    setClient(client) {
      localStorage.client_id = client._id;
      localStorage.adminSession = client.tokens[0].token;
      sessionStorage.adminSession = client.tokens[0].token;
      window.location.href = '/';
      this.client = client;
    },
  },
  async mounted() {
    if (localStorage.isv_id) {
      this.isv = await getISV();
      console.log(this.isv);
      localStorage.setItem('navEnlarge', 'true');
      this.client = this.isv.subaccounts.find((client) => client._id == localStorage.client_id);
    }
    document.getElementsByTagName('body')[0].classList.add('admin');
    if (localStorage.getItem('adminSession')) this.authed = true;
    this.setSize();
    window.addEventListener('resize', this.setSize);
    this.getSettings();
    document.addEventListener('settings_updated', this.getSettings);
    if (!localStorage.getItem('navEnlarge')) {
      localStorage.navEnlarge = 'true';
      this.navEnlarge = true;
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  },
}
</script>

<style lang="scss">
@import "/src/assets/styles/variables.scss";

body.main {
  margin: 0;
  padding: 0;
}

.v-toast {
  font-family: 'Poppins', sans-serif;
  font-size: small;
}

.main {
  #app {
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: $text;
    letter-spacing: 0.8px;

    nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100px;
      background-image: linear-gradient(to bottom, #191b1d, #1b1d1f00);
      display: flex;
      align-items: center;
      justify-content: right;
      padding: 0 10%;
      box-sizing: border-box;
      font-size: 1.1rem;
      z-index: 10;

      img {
        position: fixed;
        top: 25px;
        left: 10%;
        height: 50px;
        cursor: pointer;
      }

      a {
        text-decoration: none;
        color: white;
        font-weight: normal;
        font-size: 0.9em;
        padding: 5px 20px;
        border-right: 1px solid white;

        &.router-link-exact-active {
          text-decoration: underline;
        }

        &:last-child {
          border-right: none;
        }

        &.join,
        &.book {
          font-weight: bold;
        }
      }
    }
  }
}

.admin {

  .router-view,
  #app {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 80px);
    height: 100%;
    font-family: 'Poppins', sans-serif;
    font-size: small;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #333;
    transition: width 0.2s;

    &.free-trial {
      top: 30px;
      height: calc(100% - 30px);
    }

    &.navEnlarge {
      width: calc(100% - 230px);
    }

    h1 {
      color: $colourP;
    }

    button,
    select,
    option,
    input {
      font-family: inherit;
      font-size: inherit;
    }
  }

  #header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;

    #left {
      margin: 10px;
      font-weight: normal;
      font-size: 1.8em;
      color: #333;
    }

    #right {
      display: flex;
      align-items: center;

      select {
        background: none;
        color: #333;
        padding: 10px;
        border: solid 1px #e6e6e6;
        width: 20vw;
        max-width: 200px;
        border-radius: 5px;
        height: 42px;
        box-sizing: border-box;
        margin-left: 20px;
        background-color: white;
      }

      #weekSelect {
        width: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: solid 1px #e6e6e6;
        background-color: white;
        border-radius: 5px;
      }

      p {
        margin: 0;
        color: #333;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        box-sizing: border-box;
        height: 40px;
        transition: all 0.2s ease-in-out;
        color: #333;

        &:hover {
          cursor: pointer;
          background: #e0e0e0;
        }

        &:first-child {
          border-right: solid 1px #e6e6e6;
        }

        &:last-child {
          border-left: solid 1px #e6e6e6;
        }
      }

      b {
        box-sizing: border-box;
        padding: 10px;
        height: 40px;
        margin-left: 10px;
        color: #333;
        display: flex;
        font-size: 1.1em;
        justify-content: center;
        align-items: center;
      }

      button {
        background: none;
        padding: 10px;
        border: solid 1px #e6e6e6;
        width: 100px;
        text-align: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:first-child {
          border-radius: 5px 0 0 5px;
          border-right: none;
        }

        &:last-child {
          border-radius: 0 5px 5px 0;
        }

        &.selected {
          background: linear-gradient(140deg, $colourP, $colourS);
          color: white;
        }

        &:hover {
          background-color: $colourP;
          color: white;
        }
      }
    }
  }

  #free-trial-banner {
    position: fixed;
    top: 0;
    right: 0;
    width: calc(100% - 80px);
    height: 30px;
    background-color: $colourP;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    z-index: 10;
    transition: width 0.2s ease-in-out;

    &.navEnlarge {
      width: calc(100% - 230px);
    }
  }

  #adminDesktopNav {
    position: fixed;
    top: 0;
    left: 0;
    width: 50px;
    height: calc(100% - 60px);
    padding: 10px 15px;
    padding-bottom: 50px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #2c3e50;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: width 0.2s ease-in-out;

    #logo {
      width: 80%;
      max-height: 50px;
      margin: 12px 0;
      object-fit: cover;
    }

    a {
      font-weight: bold;
      text-decoration: none;
      color: #ccc;
      display: block;
      width: 50px;
      height: 50px;
      margin: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      transition: background-color 0.1s ease-in-out, width 0.2s ease-in-out;
      font-size: 20px;

      &:hover {
        background-color: #cccccc30;
      }

      &.router-link-exact-active {
        background-color: lighten(#2c3e50, 10%);
        color: white;

        i {
          color: white !important;
        }
      }

      img {
        width: 30px;
      }

      span {
        visibility: hidden;
        width: 0;
        opacity: 0;
        transition: visibility 0s, opacity 0.5s linear;
      }
    }

    #openNav,
    #openInfo {
      position: absolute;
      bottom: 10px;
      left: 20px;
      padding: 15px;
      font-size: medium;
      border-radius: 40px;
      width: 46px;
      box-sizing: border-box;
      color: #85888b;
      transition: all 0.2s;
      cursor: pointer;

      &:hover {
        color: white;
        background-color: lighten(#2c3e50, 10%);
      }
    }

    #openInfo {
      right: 20px;
      left: unset;
      display: none;
    }

    #isvToggle {
      position: absolute;
      bottom: 10px;
      left: 15px;
      padding: 15px;
      font-size: medium;
      border-radius: 40px;
      width: 200px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #CCCCCC;
      transition: all 0.2s;
      cursor: pointer;

      span {
        font-size: small;
      }

      i {
        font-size: 0.88em;
      }
    }

    #selectISV {
      position: absolute;
      bottom: 50px;
      left: 8px;
      width: 200px;
      background: inherit;
    }

    &.navEnlarge {
      width: 200px;

      a {
        width: 200px;
        text-align: left;
        justify-content: flex-start;
        padding-left: 5px;

        i {
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #bbb;
          font-size: 0.88em;
        }

        &.router-link-exact-active {
          color: white !important;
        }

        img {
          margin: 0 6px;
        }

        span {
          visibility: visible;
          width: auto;
          opacity: 1;
          font-weight: normal;
          font-size: small;
          margin-left: 10px;
          white-space: nowrap;
        }
      }

      #openInfo {
        bottom: 10px;
        display: block;
      }
    }

    #logout {
      cursor: pointer;
    }
  }

  @media (max-width: 600px) {
    #app {
      left: 0;
      width: 100%;
    }
  }
}

.fa-spinner {
  animation: spin 2s linear infinite;
}

#mobile-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  padding: 0 1em;

  h1 {
    font-size: 1.8em;
    margin-bottom: 0.5em;
  }

  p {
    font-size: 1.2em;
    margin-bottom: 1em;
  }
}

// Loader
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 750px) {

  .router-view,
  #app {
    left: 0;
    width: 100% !important;
  }

  .router-view.isv {
    height: calc(100% - 20px);
  }

  nav {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 2;
    background: white;
    box-shadow: #ccc 0 0 10px;
    border-radius: 30px 30px 0 0;

    &.isv {
      height: 90px;

      #flex-nav-items {
        height: 60px;
      }

      #isvToggle {
        font-size: medium;
        border-radius: 40px;
        box-sizing: border-box;
        color: #a0a0a0;
        transition: all 0.2s;
        cursor: pointer;

        span {
          font-size: small;
        }

        i {
          font-size: 0.88em;
          margin-left: 10px;
        }
      }


      #selectISV {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translate(-50%, 0);
        width: 200px;
        background: inherit;
        box-shadow: #ccc 0 0 10px;

        a {
          width: 100%;
          text-align: center;
          padding: 10px 0;
          border-bottom: 1px solid #ccc;
          display: block;
          color: #333;
          font-size: 1.1em;
          text-decoration: none;

          i {
            margin-right: 10px;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }

    #flex-nav-items {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      height: 70px;

      a {
        color: #333;
        font-size: 1.2em;
        text-decoration: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        transition: background-color 0.2s ease-in-out;

        i {
          font-size: 1.1em;
          color: #2c3e50;
        }

        &.router-link-exact-active {
          font-size: 1.3rem;
        }
      }
    }
  }
}
</style>
