import { createRouter, createWebHistory } from 'vue-router'
import MessagesView from '../views/MessagesView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      requiresAdminAuth: true
    },
    component: MessagesView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "adminLogin" */ '../views/LoginView.vue')
  },
  {
    path: '/messages',
    name: 'messages',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminMessages" */ '../views/MessagesView.vue')
  },
  {
    path: '/calls',
    name: 'calls',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminCalls" */ '../views/CallsView.vue')
  },
  {
    path: '/channels',
    name: 'channels',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminChannels" */ '../views/ChannelsView.vue')
  },
  {
    path: '/info',
    name: 'info',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminInfo" */ '../views/InfoView.vue')
  },
  {
    path: '/isv',
    name: 'isv',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminInfo" */ '../views/ISVView.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "adminSettings" */ '../views/SettingsView.vue')
  },
  {
    path: '/reset_user_password/:id',
    name: 'password',
    component: () => import(/* webpackChunkName: "adminPasswordReset" */ '../views/PasswordView.vue')
  },
  {
    path: '/data-harvest',
    name: 'data-harvest',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "data-harvest" */ '../views/DataHarvestView.vue')
  },
  {
    path: '/data-cleaning',
    name: 'data-cleaning',
    meta: {
      requiresAdminAuth: true
    },
    component: () => import(/* webpackChunkName: "data-cleaning" */ '../views/DataCleaningView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.userSession) {
      next({ name: 'login', query: { p: to.name } });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta.requiresAdminAuth)) {
    if (!localStorage.adminSession) {
      next({ name: 'login' });
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router
