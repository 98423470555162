<template>
  <div class="ai">
    <div class="conversations">
      <div id="messages-header" v-if="mobile">
        <div id="flex">
          <h1>Messages</h1>
          <img src="@/assets/logo.png" alt="Logo" />
        </div>
        <div id="search">
          <i class="fas fa-search"></i>
          <input type="text" placeholder="Search conversations..." v-model="search" />
        </div>
      </div>
      <div id="search" v-else>
        <i class="fas fa-search"></i>
        <input type="text" placeholder="Search conversations..." v-model="search" />
      </div>

      <div class="conversation" v-for="conversation in visibleConversations" :key="conversation.id"
        :class="conversation.contact == this.conversation ? 'active' : ''" @click="getConversation(conversation)">
        <h3 class="conversation-contact" :class="conversation.paused ? 'paused' : ''">
          <i v-if="conversation.method == 'whatsapp'" class="fab fa-whatsapp"></i>
          <i v-else-if="conversation.method == 'sms'" class="fas fa-sms"></i>
          <i v-else-if="conversation.method == 'email'" class="fa-regular fa-envelope"></i>
          <div>
            <div>
              {{ conversation.name ? conversation.name.replace('(empty)', '') : conversation.contact }}
              <i v-if="conversation.paused" class="fas fa-pause" style="margin-left: 10px; display: inline-block;"></i>
            </div>
            <div class="sub-contact" v-if="conversation.name">{{ conversation.contact }}</div>
          </div>
        </h3>
        <p class="conversation-sample">{{ conversation.lastMessage.substring(0, 45) }}{{ conversation.lastMessage.length
          > 45 ? '...' : '' }}</p>
        <p class="conversation-date">{{ new Date(conversation.lastMessageDate).toString().substring(15, 21) }},
          {{ new Date(conversation.lastMessageDate).toISOString().substring(0, 10) }}</p>
      </div>

      <div class="conversation" v-if="conversations.length == 0 && !conversationsLoading">
        <p style="margin: auto">Your messages will appear here...</p>
      </div>

      <div class="fas fa-spinner" v-if="conversationsLoading"></div>
    </div>

    <div id="conversation" v-if="!mobile || conversation">
      <div id="mobile-convo-header" v-if="mobile">
        <a @click="this.conversation = ''" style="font-size: 1.5em; margin-left: 20px; cursor: pointer;">&lt;</a>
        <h1>{{ conversations.find(c => c.contact.toString() == this.conversation.toString()).name ||
          conversations.find(c => c.contact.toString() == this.conversation.toString()).contact }}</h1>
        <a href=""></a>
      </div>

      <div class="messages" ref="messages" :class="conversationMethod">
        <div v-for="message in messages" :key="message._id">
          <span class="divider" v-if="newMessageDay(message)"><i></i> {{ new
            Date(message.date).toISOString().substring(0, 10) }} <i></i></span>
          <div class="message" :class="message.to == 'system' ? 'user' : 'system'">
            <p>
            <div v-html="message.message.replace(/\n/g, '<br/>')"></div>
            </p>
            <div class="msg-date">{{ new Date(message.date).toString().substring(15, 21) }}, {{ new
              Date(message.date).toISOString().substring(0, 10) }}</div>
          </div>
        </div>

        <div class="message" v-if="!conversation && !messagesLoading">
          <p style="margin: auto">Click a conversation to see messages</p>
        </div>
        <div class="message" v-else-if="messages.length == 0 && !messagesLoading">
          <p style="margin: auto">No messages</p>
        </div>
        <div class="fas fa-spinner" v-if="messagesLoading"></div>

        <button id="conversationPaused" v-if="conversationPaused && !messagesLoading" @click="unpauseConversation">AI
          responses paused.
          Click to resume.</button>
      </div>
      <div id="message-bar" @click="checkMessagingPossible">
        <input type="text" v-if="conversationMethod != 'email'" v-model="message" placeholder="Type a message..." />
        <textarea v-else v-model="message" placeholder="Type an email..."></textarea>
        <button @click="sendMessage" :class="conversationMethod">{{ conversationMethod == 'email' ? 'Send' : '' }} <i
            class="fa-solid fa-paper-plane"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getConversations,
  getConversation,
  sendMessage,
  unpauseAccount
} from '@/app/admin-routes'

export default {
  name: 'MessagesView',
  props: {
    showMobileNav: Function,
    hideMobileNav: Function,
  },
  data() {
    return {
      // Add data here
      mobile: window.innerWidth < 750,
      conversations: [],
      visibleConversations: [],
      conversation: '',
      conversationMethod: '',
      conversationsLoading: false,
      conversationPaused: false,
      messages: [],
      message: '',
      messagesLoading: false,
      search: '',
    };
  },
  methods: {
    async getConversations() {
      this.conversationsLoading = true;
      const conversations = await getConversations();
      console.log(conversations);
      this.conversationsLoading = false;
      this.conversations = conversations.sort((a, b) => new Date(b.lastMessageDate) - new Date(a.lastMessageDate));
      this.visibleConversations = this.conversations;
      const urlParams = new URLSearchParams(window.location.search);
      const chat = urlParams.get('chat');
      if (chat) {
        this.getConversation({
          contact: chat
        });
      } else if (conversations.length && !this.mobile) this.getConversation(conversations[0]);
    },
    async getConversation(conversation) {
      this.messages = [];
      this.messagesLoading = true;
      this.conversation = conversation.contact.toString();
      this.conversationPaused = conversation.paused;
      const messages = await getConversation(conversation.contact);
      console.log(messages);
      this.messagesLoading = false;
      this.messages = messages;
      this.message = '';
      this.conversationMethod = conversation.method;
      this.$nextTick(() => {
        const messages = this.$refs.messages;
        messages.scrollTop = messages.scrollHeight;
      });
    },
    filterConversations() {
      const search = this.search.toLowerCase();
      this.visibleConversations = this.conversations.filter(c => c.contact.toString().toLowerCase().includes(search) || c.name.toLowerCase().includes(search));
    },
    async sendMessage() {
      const message = this.message;
      if (!message) return;
      this.message = '';
      const conversation = this.conversations.find(c => c.contact.toString() == this.conversation.toString());
      if (conversation.method == 'whatsapp' && new Date(conversation.lastMessageDate) < new Date(new Date().getTime() - 24 * 60 * 60 * 1000)) {
        this.$toast.open({
          message: 'You cannot send messages to WhatsApp numbers more than 24 hours since the last message.',
          type: 'error',
          position: 'top'
        });
        return;
      }
      await sendMessage({
        number: conversation.contact,
        message,
        method: conversation.method
      });
      this.messages.push({
        message,
        to: 'user',
        date: new Date()
      });
      this.$nextTick(() => {
        const messages = this.$refs.messages;
        messages.scrollTop = messages.scrollHeight;
      });
      this.conversations.find(c => c.contact.toString() == this.conversation.toString()).paused = true;
      this.conversationPaused = true;
    },
    async unpauseConversation() {
      const response = await unpauseAccount(this.conversation);
      if (response) this.conversationPaused = false;
      this.conversations.find(c => c.contact.toString() == this.conversation.toString()).paused = false;
    },
    newMessageDay(message) {
      const index = this.messages.findIndex(m => m._id == message._id);
      if (index == 0) return true;
      const previous = this.messages[index - 1];
      return new Date(message.date).toISOString().substring(0, 10) != new Date(previous.date).toISOString().substring(0, 10);
    },
    checkMessagingPossible() {
      const conversation = this.conversations.find(c => c.contact.toString() == this.conversation.toString());
      if (conversation.method == 'whatsapp' && new Date(conversation.lastMessageDate) < new Date(new Date().getTime() - 24 * 60 * 60 * 1000)) {
        this.$toast.open({
          message: 'You cannot send messages to WhatsApp numbers more than 24 hours since the last message.',
          type: 'error',
          position: 'top'
        });
      }
    },
    setSize() {
      this.mobile = window.innerWidth < 750;
      if (this.conversation) this.hideMobileNav();
      else this.showMobileNav();
    },
  },
  mounted() {
    this.getConversations();
    document.querySelector('input').addEventListener('keypress', (e) => {
      if (e.key === 'Enter') {
        this.sendMessage();
      }
    });
    window.addEventListener('resize', this.setSize);
  },
  watch: {
    search() {
      this.filterConversations();
    },
    conversation() {
      if (this.conversation) this.hideMobileNav();
      else this.showMobileNav();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setSize);
  }
};
</script>

<style lang="scss" scoped>
@import "/src/assets/styles/variables.scss";

.ai {
  display: flex;

  .conversations {
    width: 350px;
    max-width: 50%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 0px;
    padding: 10px;
    background-color: white;
    box-sizing: border-box;

    #search {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      margin-bottom: 20px;
      background-color: #f4f8f9;
      border-radius: 50px;

      i {
        margin-right: 10px;
        color: #bebebe;
      }

      input {
        border: none;
        background-color: transparent;
        width: calc(100% - 20px);
        font-size: 1em;

        &:focus {
          outline: none;
        }
      }
    }

    .conversation {
      width: calc(100% - 40px);
      padding: 20px;
      margin-bottom: 20px;
      box-shadow: #e2e2e2 0 0 10px;
      border-radius: 6px;
      overflow-x: hidden;
      cursor: pointer;

      .paused {
        color: #b38002;
      }

      h3 {
        margin: 0;
        font-size: 1.05em;
        text-align: left;
        display: flex;
        align-items: center;
        word-break: keep-all;

        i {
          font-size: 1.6em;
          margin-right: 12px;
        }

        .sub-contact {
          font-size: 0.8em;
          color: #666;
          font-weight: normal;
        }
      }

      p {
        margin: 0;
        margin-top: 15px;
        text-align: left;
        // color: #777;
        font-size: 0.9em;
      }

      .conversation-date {
        font-size: 0.8em;
        color: #8d8d8d;
        margin-top: 5px
      }

      &:nth-child(even) {
        background-color: #ffffff90;
      }

      &:hover {
        background-color: #f0f0f0;
      }

      &.active {
        background-color: #cce7ee;
        border-left: solid 4px $colourP;
      }
    }

    @media screen and (min-width: 750px) {
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: #aaa;
        }
      }
    }
  }

  #conversation {
    width: calc(100% - 350px);
    min-width: 50%;
    margin-left: 4px;
    background-color: white;
    border-left: 1px solid #e3e3e3;

    .messages {
      width: 100%;
      height: calc(100% - 81px);
      overflow-y: scroll;

      &.email {
        height: calc(100% - 41px - 100px);
      }

      .message {
        padding: 10px;
        margin: 10px;
        border-radius: 10px;
        color: black;
        width: calc(100% - 20px);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        word-break: break-word;

        p {
          padding: 10px 20px;
          border-radius: 5px;
          max-width: 80%;
          box-shadow: #ccc 0 0 10px;

          span {
            font-size: 0.8em;
            color: #666;
          }
        }

        .msg-date {
          font-size: 0.8em;
          color: #8d8d8d;
          margin: 0 5px;
          text-align: right;
        }

        &.user {
          align-items: flex-start;

          p {
            text-align: left;
            border-radius: 8px 8px 8px 0;
            box-shadow: #e2e2e2 0 0 10px;
          }
        }

        &.system {
          align-items: flex-end;

          p {
            background: linear-gradient(45deg, #2596be, #2562be);
            text-align: left;
            color: white;
            border-radius: 8px 8px 0 8px;

            span {
              color: #ccc;
            }
          }
        }
      }

      .divider {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;

        i {
          width: 20%;
          margin: 0 15px;
          height: 1px;
          background-color: #e3e3e3;
        }
      }

      #conversationPaused {
        padding: 7px 30px;
        margin-bottom: 20px;
        border: 3px solid $colourP;
        border-radius: 7px;
        background-color: white;
        color: $colourP;
        font-size: 13px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background-color: $colourP;
          color: white;
        }
      }
    }

    #message-bar {
      display: flex;
      border-top: 1px solid #e3e3e3;

      input {
        flex: 1;
        padding: 12px 20px;
        margin: 18px 20px;
        background-color: #f6f6f6;
        border: none;
        border-radius: 100px;
        padding-right: 60px;

        ::placeholder {
          color: #cfcfcf;
        }

        &:focus {
          outline: none;
        }
      }

      textarea {
        flex: 1;
        padding: 10px;
        border: none;
        border-radius: 0 0 0 5px;
        height: 120px;
        font-family: inherit;
      }

      button {
        position: absolute;
        right: 20px;
        bottom: 15px;
        background-color: $colourP;
        border: none;
        color: white;
        border-radius: 50px;
        box-shadow: #ccc 0 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        cursor: pointer;

        &.email {
          position: absolute;
          right: 20px;
          bottom: 20px;
          width: 120px;
          border: none;
          border-radius: 5px;

          i {
            margin-left: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .ai {
    display: block;

    .conversations {
      width: 100%;
      min-width: 100%;
      margin-left: 0;
      border-left: none;
      margin-top: -10px;
      padding-bottom: 70px;

      #messages-header {
        padding: 10px 20px;
        box-sizing: border-box;
        margin: 0;
        margin-left: -10px;
        margin-bottom: 20px;
        width: 100vw;
        background-color: white;
        box-shadow: #e3e3e3 0 0 10px;
        border-radius: 0 0 30px 30px;
        z-index: 1;

        #flex {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 10px 0;

          h1 {
            margin: 0;
            font-size: 1.6em;
            font-weight: normal;
            color: unset;
          }

          img {
            width: 30px;
            margin-left: 10px;
          }
        }

        #search {
          margin-bottom: 10px;
        }
      }

      .conversation {
        background: #f3f3f3 !important;
        box-shadow: none;
      }

      .conversation.active {
        border-left: none;
        background-color: white;
      }
    }

    #conversation {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 4;
      margin: 0;
      background-color: #f3f3f3;

      #mobile-convo-header {
        padding: 20px;
        box-sizing: border-box;
        margin: 0;
        width: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
          margin: 0;
          font-size: 1.4em;
          font-weight: normal;
          color: unset;
        }

        p {
          font-size: 1em;
        }

        a {
          width: 10px;
        }
      }

      .messages {
        background-color: white;
        border-radius: 30px 30px 0 0;
        height: calc(100% - 150px);
      }

      #message-bar {
        background-color: white;
      }
    }
  }
}
</style>